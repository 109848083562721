import React from "react";
import { ArticleCard, Share, MiniNewsletter } from "@molecules";
import { Container } from "@atoms";

const BlogHero = ({ newsletter, article }) => {
  return (
    <section className="relative z-30 -mt-10 pt-10 sm:-mt-20 md:pt-16">
      <Container className="relative z-10">
        <div className="flex flex-wrap gap-20 md:gap-10">
          <div className="flex-1">
            <ArticleCard {...article} feature />
          </div>
          <div className="hidden w-full flex-col gap-5 sm:flex md:w-1/3">
            <Share className="md:text-white" />
            <MiniNewsletter {...newsletter} />
          </div>
        </div>
      </Container>
    </section>
  );
};

BlogHero.defaultProps = {};

export default BlogHero;
